import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入富文本编辑器
import quillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import * as echarts from 'echarts' //引入echarts
import Cookie from 'js-cookie'
import qs from 'qs'
import {
  fixedRouter
} from '@/router'
// axios配置
import instance from './util/http.js'
import GLOBAL from "./util/global";
import VueAnimateNumber from 'vue-animate-number'
import {
  getStatusName,
  formatDate
} from './util'

import JsonExcel from 'vue-json-excel'


import versionTood from '@/libs/versionUpdate'


Vue.component('downloadExcel', JsonExcel)

Vue.use(VueAnimateNumber)

Vue.prototype.$qs = qs

Vue.prototype.$global = GLOBAL;
Vue.prototype.$http = instance
Vue.prototype.$getStatusName = getStatusName
Vue.prototype.$formatDate = formatDate


// 长连接
import * as socketApi from '@/util/websocket.js'
Vue.prototype.socketApi = socketApi

import * as socketApiCarport from '@/util/websocketCarport.js'
Vue.prototype.socketApiCarport = socketApiCarport

// 安全帽长连接
import * as socketCapsApi from '@/util/websocketCaps.js'
Vue.prototype.socketCapsApi = socketCapsApi

// 电梯棚长连接
import * as socketCarportApi from '@/util/websocketCarport.js'
Vue.prototype.socketCarportApi = socketCarportApi

// 中英文切换
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)


// vuex
import Vuex from 'vuex';
Vue.use(Vuex)

Vue.prototype.$mapCode = require('@/assets/pcas-code.json')

Vue.prototype.$echarts = echarts //引入组件
Vue.use(ElementUI);
Vue.use(VueAxios, axios);
Vue.use(quillEditor)
Vue.config.productionTip = false

import VueRouter from 'vue-router'
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

Vue.use(VueRouter)


// 中英文切换
const i18n = new VueI18n({
  locale: 'zh',
  messages: {
    'zh': require('@/lang/zh.js'),
    'en': require('@/lang/en.js')
  }
})

// 注册自定义指令
let onRot;
let butList = [];
let butObj = null;
// let isWbjb = null;

Vue.directive('focus', {

  // 当被绑定的元素插入到 DOM 中时……
  bind: function(el, binding, vnode) {
    butObj = JSON.parse(sessionStorage.getItem("BUTTON_TYPE"))
    let bKey = '***'
    let obj = butObj ? butObj.find(item => item.label == el.innerText.replace(/\s*/g, "")) : ''

    if (obj != undefined) {
      bKey = onRot + obj.value
    }
    if (butList.indexOf(bKey) == -1 || butList.indexOf(obj.value) == -1) {
      el.style = "display:none"
    }
  }
})
// Vue.directive('wbjbHide', {
//   // 当被绑定的元素插入到 DOM 中时……
//   bind: function (el, binding, vnode) {
//     isWbjb = JSON.parse(sessionStorage.getItem("isWBJB"))
//     console.log(isWbjb,'butObj');
//     if (isWbjb) {
//       el.style = "display:none"
//     }
//   }
// })
Vue.directive('loadTableMore', {
  bind(el, binding) {
    // 获取当前滚动的节点，注意必须要有高度
    const selectWrap = el.querySelector('.el-table__body-wrapper')
    if (selectWrap) {
      selectWrap.addEventListener('scroll', function(e) {
        let sign = 3
        // 这个sign值，只是为了滚动到最底部3px的时候加载
        const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
        if (Math.ceil(scrollDistance) <= sign) {
          // 到这里 就会执行方法了
          binding.value()
        }
      })
    } else {
      console.log('没找到元素')
    }
  },
})

let isT = false
// 路由守卫
router.beforeEach((to, from, next) => {
  // versionTood.isNewVersion();
  // 获取路由去设置匹配id
  let rArr = to.path.split('/')
  onRot = rArr[rArr.length - 1]

  let token = sessionStorage.getItem('AdminToken')

  // 动态修改标签页标头
  // document.title = to.name
  let adminInfo = JSON.parse(sessionStorage.getItem('adminInfo'))
  axios.defaults.headers.common['Authorization'] = token
  // if (adminInfo != null) {
  // 	axios.defaults.headers.common['resource_key'] = adminInfo.id
  // }
  if (to.path == "/login") {
    isT = false
    butObj = null
  }
  // if ((adminInfo == null || token == null) && to
  // 	.path != "/login" && to
  // 	.path != "/stampPage" && to
  // 	.path != "/elevatorSigns") {
  // 	next({
  // 		path: "/login"
  // 	});
  if ((adminInfo == null || token == null) && to.path != "/login" && to.path != "/loginWait" && to.path !=
    "/stampPage" && to.path != "/YddPage" && to.path != "/ggStock") {
    console.log('进这里');
    if (window.location.href.split('?').length > 1) {
      const AppKey = window.location.href.split('?')[1].split('&')[0].split('=')[1]
      const AppSecret = window.location.href.split('?')[1].split('&')[1].split('=')[1]
      const AppType = window.location.href.split('?')[1].split('&').length > 2 ? window.location.href.split('?')[1]
        .split('&')[2].split('=')[1] : 1
      sessionStorage.setItem('AppKey', AppKey)
      sessionStorage.setItem('AppSecret', AppSecret)
      sessionStorage.setItem('AppType', AppType)
      // sessionStorage.setItem('AdminToken', token)
      next({
        path: "/loginWait"
      });
      // next();
    } else {
      console.log('login');
      next({
        path: "/login"
      });
    }
  } else {
    if (butObj == null) {
      if (sessionStorage.getItem("adminInfo") != null) {
        butList = JSON.stringify(JSON.parse(sessionStorage.getItem("adminInfo")).buttons)
        butObj = JSON.parse(sessionStorage.getItem("BUTTON_TYPE"))
      }
    }
    next();
  }
  // 动态添加路由
  if (adminInfo != null && !isT && to.path != "/login") {
    isT = true
    var getRoutes = adminInfo.resource
    // console.log('getRoutes', getRoutes)
    let arr = getTreeData(getRoutes)
    router.options.routes = fixedRouter.concat(arr);
    router.addRoutes(fixedRouter.concat(arr))
    router.push({
      path: to.path
    })
  }

})

function getTreeData(data) {
  for (var i = 0; i < data.length; i++) {
    const url = data[i].routeUrl
    // 去掉.vue
    // let uu = url.slice(0, url.length - 4)
    data[i].component = resolve => require([`@/views${url}.vue`], resolve)
    if (data[i].typeId == 2) {
      data[i].children = []
    }
    if (data[i].children != null && data[i].typeId != 2) {
      getTreeData(data[i].children);
    }
  }
  return data;
}

const store = new Vuex.Store({
  state: {
    userAgent: null, //webrtc对象
    userAgentStatus: false, //webrtc对象注册是否成功
    tabHeight: 600, //webrtc对象注册是否成功
    isZx: false, //webrtc对象注册是否成功
    StaSeleTheme: false, //简介模式
    isWBJB: false
  },
  mutations: {
    changeTheme(state, bool) {
      state.StaSeleTheme = bool
    }
  }
});

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
