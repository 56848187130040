<template>
  <div :class="className" :style="{width:width, height:height}" />
</template>

<script>
  import echarts from 'echarts'
  import {
    formatDate
  } from "@/util";
  export default {
    props: {
      className: {
        type: String,
        default: 'chart',
      },
      width: {
        type: String,
        default: '100%',
      },
      height: {
        type: String,
        // default: 'calc(100% - 30px)'
        default: '240px',
      },
      autoResize: {
        type: Boolean,
        default: true,
      },
      eleMain: {
        type: Object,
        default: () => {
          return {
            xAxis: [1, 5, 10, 15, 21, 25, 31, 1, 5, 10, 15, 21, 25, 31], // x轴
            seriesData: [0, 0, 0, 0, 0], // 数据
          }
        },
      },
    },

    data() {
      return {
        chart: null,
        echart1: [],
        echart2: [],
        echart3: [],
        nums: [],
        data1: [],
        data2: [],
        data3: [],
        echartColor: [],
      }
    },

    // 监听data里面对应的
    watch: {
      data: {
        deep: true,
        handler(val) {
          this.setOptions(val)
        },
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.initChart()
      })
    },

    beforeDestroy() {
      if (!this.chart) return
      // 释放图表实例
      this.chart.dispose()
      this.chart = null
    },

    methods: {
      initChart() {
        this.chart = this.$echarts.init(this.$el)
        this.setOptions(this.eleMain)
      },
      setOptions(data) {
        const {
          seriesData,
          xAxis
        } = data
        for (var i = 1; i <= xAxis.length; i++) {
          let num = 0
          if (i < 10) {
            num = '0' + i
          } else {
            num = JSON.stringify(i)
          }
          this.nums.push({
            color: '',
            date: num,
            title: 0
          })
        }
        // let Arr=[]
        seriesData.forEach((item) => {
          if (item.color == '#52C41A') {
            this.echart1.push({
              color: item.color ? item.color : '',
              date: item.date ? formatDate(new Date(item.date), 'dd') : '',
              title: item.title ? item.title : 0,
            })
            return
          }
          if (item.color == '#5958d4') {
            this.echart2.push({
              color: item.color ? item.color : '',
              date: item.date ? formatDate(new Date(item.date), 'dd') : '',
              title: item.title ? item.title : 0,
            })
            return
          }
          if (item.color == '#FF5722') {
            this.echart3.push({
              color: item.color ? item.color : '',
              date: item.date ? formatDate(new Date(item.date), 'dd') : '',
              title: item.title ? item.title : 0,
            })
            return
          }
        })
        // 已完成
        let newList1 = this.nums.map((item) => {
          let index = this.echart1.findIndex(e =>
            e.date == item.date
          )
          if (index > -1) {
            item = this.echart1[index];
          }
          return item;
        });
        this.echart1 = newList1
        for (var i = 0; i < this.echart1.length; i++) {
          this.data1.push(this.echart1[i].title)
        }

        // 未完成
        let newList2 = this.nums.map((item) => {
          let index = this.echart2.findIndex(e =>
            e.date == item.date
          )
          if (index > -1) {
            item = this.echart2[index];
          }
          return item;
        });
        this.echart2 = newList2
        for (var i = 0; i < this.echart2.length; i++) {
          this.data2.push(this.echart2[i].title)
        }

        // 已超期
        let newList3 = this.nums.map((item) => {
          let index = this.echart3.findIndex(e =>
            e.date == item.date
          )
          if (index > -1) {
            item = this.echart3[index];
          }
          return item;
        });
        this.echart3 = newList3
        for (var i = 0; i < this.echart3.length; i++) {
          this.data3.push(this.echart3[i].title)
        }

        const options = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            data: ['已完成', '未完成', '已超期'],
            color: 'grey',
            textStyle: {
              color: '#409eff',
            }
          },
          textStyle: {
            color: '#409eff', // 所有文字的颜色
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: [{
            type: 'category',
            data: xAxis,
            //底边刻度间距
            // axisLabel:{
            //   interval:5
            // },
            interval: 5,
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              lineStyle: {
                color: '#409eff', // 坐标轴颜色
              },
            },
          }, ],
          yAxis: [{
            type: 'value',
            min: 0,
            max: 25,
            axisLine: {
              lineStyle: {
                color: '#409eff', // 坐标轴颜色
              },
            },
          }, ],
          series: [{
            name: "已完成",
            type: "bar", //柱状图
            stack: "Search Engine",
            emphasis: { //折线图的高亮状态。
              focus: "series", //聚焦当前高亮的数据所在的系列的所有图形。
            },
            barWidth: 25,
            data: this.data1,
            itemStyle: {
              color: '#52C41A'
            }
          }, {
            name: "未完成",
            type: "bar", //柱状图
            stack: "Search Engine",
            emphasis: { //折线图的高亮状态。
              focus: "series", //聚焦当前高亮的数据所在的系列的所有图形。
            },
            barWidth: 30,
            itemStyle: {
              color: '#5958d4'
            },
            data: this.data2,
          }, {
            name: "已超期",
            type: "bar", //柱状图
            stack: "Search Engine",
            emphasis: { //折线图的高亮状态。
              focus: "series", //聚焦当前高亮的数据所在的系列的所有图形。
            },
            barWidth: 30,
            itemStyle: {
              color: '#FF5722'
            },
            data: this.data3,
          }, ],
        }
        this.chart.setOption(options)
      },
    },
  }
</script>

<style>
</style>
