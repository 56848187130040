<template>
  <div class="admin-project-myproject-container">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="日期">
        <el-date-picker v-model="formInline.allTime" type="daterange" @change="disableddate" :max="maxRange"
          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 300px;">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <span slot="label">
          <span class="lab-span">报警类型</span>
        </span>
        <el-select v-if="!(adminInfo.roleId == '99')" v-model="formInline.sosTypeOption" placeholder="请输入" filterable
          multiple clearable style="width:210px" @change="checkSosType($event)">
          <div v-for="item in typeDate" :key="item.value">
            <el-option v-if="item.value != 35 || adminInfo.userName == 'admin'" :label="item.label"
              :value="item.value" />
          </div>
        </el-select>
        <el-select v-else v-model="formInline.sosTypeOption" placeholder="请输入" multiple clearable style="width:210px"
          @change="checkSosType($event)">
          <el-option v-for="item in simpleSosType" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <span slot="label">
          <span class="lab-span">报警状态</span>
        </span>
        <el-select v-model="formInline.alarmState" placeholder="请输入" clearable style="width:210px">
          <el-option v-for="item in stateType" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <span slot="label">
          <span class="lab-span">应急状态</span>
        </span>
        <el-select v-model="formInline.sosStatus" placeholder="请输入" clearable style="width:210px">
          <el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onRefer" class="searchBtn">查看</el-button>
      </el-form-item>
    </el-form>
    <div v-if="dictionarTotal!=0">
      <el-table :data="tableData" v-loading="tableLoading" ref="multipleTable" :height="$store.state.tabHeight-180"
        style="width: 100%;margin-bottom: 20px;" @sort-change="handleSort">
        <el-table-column type="index" label="序号" width="50px" />
        <el-table-column align='center' prop="eleName" label="电梯名称" width="200px" />
        <el-table-column align='center' prop="sosTypeName" label="报警类型" width="100px">
          <template slot-scope="scope">
            {{scope.row.sosType == -1 ? scope.row.sosText : scope.row.sosTypeName}}
          </template>
        </el-table-column>
        <el-table-column align='center' prop="sosTime" label="报警时间" width="200px" sortable />
        <el-table-column align='center' prop="callTime" label="接警时间" width="200px" sortable />
        <el-table-column align='center' prop="presentTime" label="到场时间" width="200px" />
        <el-table-column align='center' prop="finishTime" label="完成时间" width="200px" />
        <el-table-column align='center' label="报警状态">
          <template slot-scope="scope">
{{ scope.row.sosTypeName == '电动车识别报警' && scope.row.alarmState == 0 ? '-' : stateType.find((item)=>item.value == scope.row.alarmState).label }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          align='center'
          prop="sosFloor"
          label="报警楼层"
          width="100px"
        /> -->
        <el-table-column align='center' prop="useTime" label="救援时长" width="200px">
          <template #header>
            <div>
              <span style="margin-right: 5px;">救援时长</span>
              <el-tooltip class="item" effect="dark" content="救援时长 = 完成时间 -  报警时间" placement="top">
                <i class="el-icon-warning-outline" style="color: #00f6ff;font-size: 16px;"></i>
              </el-tooltip>
            </div>
          </template>
          <template slot-scope="scope">
            {{scope.row.useTime ? scope.row.useTime : '-'}}
          </template>
        </el-table-column>
        <el-table-column align='center' prop="sosStatusName" label="应急状态" width="100px" />
        <el-table-column align='center' prop="sosCount" label="连续报警次数" width="150px" />
        <el-table-column align='center' prop="finallySosTime" label="最后一次报警时间" width="200px" />
        <el-table-column align='center' prop="eleCode" label="电梯注册码" width="200px" />
        <el-table-column v-if="isShow" align='center' prop="propertyUnitName" label="物业单位" width="200px" />
        <el-table-column v-if="isShow" align='center' prop="maintainUnitName" label="维保单位" width="200px" />
        <el-table-column v-if="isShow" align='center' prop="maintainUsers" label="维保人员" width="200px" />
        <el-table-column fixed="right" align='center' prop="address" label="操作" width="120px">
          <template slot-scope="scope">
            <el-button @click="showDetails(scope.row)" type="primary" size="small">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
        @pagination="dictionarPage" />
    </div>
    <div class="nullDate" v-else v-loading="tableLoading">
      <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
    </div>
    <el-dialog v-if='dioDetails' :close-on-click-modal='false' :visible.sync="dioDetails" :append-to-body="true"
      width="1200px" top='15vh'>
      <div class="tabBut" style="text-align: center">
        <!-- <template v-for="(item, index) in detailsItem.seleArr"> -->

        <el-button @click="detailsItem.seleId=1" :type='1 == detailsItem.seleId ? "primary" : ""'>报警消息</el-button>
        <el-button v-show="dioDetailsItem.sosTypeName == '安全回路'" @click="detailsItem.seleId=2"
          :type='2 == detailsItem.seleId ? "primary" : ""'>运行参数</el-button>
        <el-button @click="detailsItem.seleId=3" :type='3==detailsItem.seleId ? "primary" : ""'>处置流程</el-button>
        <el-button v-if="dioDetailsItem.voiceRescordDTOS.length > 0 || dioDetailsItem.eleSmsRescordDTOS.length > 0"
          @click="detailsItem.seleId=4" :type='4==detailsItem.seleId ? "primary" : ""'>报警通知</el-button>
        <!-- </template> -->
      </div>
      <div style="height: 55vh;margin-top: 10px;overflow-y: auto;" class="scrollElement"
        :class="[detailsItem.seleId==1?'yeScroll':'noScroll']">
        <AlarmMessage v-if="detailsItem.seleId==1" :dioData="dioDetailsItem" />
        <Operational v-else-if="detailsItem.seleId==2" :dioData="dioDetailsItem" />
        <Process v-else-if="detailsItem.seleId==3" :dioData="dioDetailsItem" />
        <AlarmNotice v-else :dioData="dioDetailsItem" />
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: center;">
        <div v-if="detailsItem.seleId == 1 && dioDetailsItem.sosStatus === 1">
          <template v-if="
          	dioDetailsItem.sosType == 95 ||
          	dioDetailsItem.sosType == 0 ||
          	dioDetailsItem.sosType == 10 ||
          	dioDetailsItem.sosType == 7 ||
          	dioDetailsItem.sosType == 3 ||
          	dioDetailsItem.sosType == 4 ||
          	dioDetailsItem.sosType == 5 ||
          	dioDetailsItem.sosType == 11 ||
          	dioDetailsItem.sosType == 12 ||
          	dioDetailsItem.sosType == 13 ||
            dioDetailsItem.sosType == 96 ||
            dioDetailsItem.sosType == 1 ||
            dioDetailsItem.sosType == 2 ||
            dioDetailsItem.sosType == 14 ||
            dioDetailsItem.sosType == 999 ||
            dioDetailsItem.sosType == 98 ||
            dioDetailsItem.sosType == 21 ||
            dioDetailsItem.sosType == 20 ||
            dioDetailsItem.sosType == 19 ||
            dioDetailsItem.sosType == 15 ||
            dioDetailsItem.sosType == 109
          ">
            <el-button :disabled="disabled" size="mini" type="primary" @click="sosClick(1)"
              v-if="dioDetailsItem.sosType != 109&&dioDetailsItem.sosType != 15&&dioDetailsItem.sosType != 13&&dioDetailsItem.sosType != 98">
              <span>人为触发(无故障)</span>
              <p style="margin: 10px 0 0 0;">确认关闭</p>
            </el-button>
            <el-button :disabled="disabled" size="mini" type="primary" @click="sosClick(6)"
              v-if="dioDetailsItem.sosType == 13">
              <span>确认</span>
              <p style="margin: 10px 0 0 0;">正常报警非误报</p>
            </el-button>
            <el-button size="mini" type="info"
              v-if="dioDetailsItem.sosType != 109&& dioDetailsItem.sosType != 21 &&dioDetailsItem.sosType != 15 && dioDetailsItem.sosType != 98"
              @click="sosClick(2)" :disabled="disabled">
              <span>电梯故障(无困人)</span>
              <p style="margin: 10px 0 0 0;">派发维修工单</p>
            </el-button>
            <el-button size="mini" type="warning"
              v-if="dioDetailsItem.sosType != 109&& dioDetailsItem.sosType != 21 &&dioDetailsItem.sosType != 19 &&dioDetailsItem.sosType != 20 && dioDetailsItem.sosType != 15&&dioDetailsItem.sosType != 13&& dioDetailsItem.sosType != 96"
              @click="sosClick(3)" :disabled="disabled">
              <span>困人</span>
              <p style="margin: 10px 0 0 0;">立即派发救援工单</p>
            </el-button>
            <el-button class="errorSos" size="mini"
              v-if="dioDetailsItem.sosType != 109&&dioDetailsItem.sosType != 15&& dioDetailsItem.sosType != 98"
              @click="sosClick(5)" :disabled="disabled">
              <span>误报</span>
              <p style="margin: 10px 0 0 0;">设备产生错误报警</p>
            </el-button>
          </template>
        </div>
      </span>
      <!-- <div slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: center;">
        <div v-if="detailsItem.seleId==1">
          <el-button :disabled='dioDetailsItem.sosStatus!=1' size="mini" type="primary" @click="sosClick(1)">
            <span>人为触发(无故障)</span>
            <p style="margin: 10px 0 0 0;">确认关闭</p>
          </el-button>
          <el-button :disabled='dioDetailsItem.sosStatus!=1' size="mini" type="info" @click="sosClick(2)">
            <span>电梯故障(无困人)</span>
            <p style="margin: 10px 0 0 0;">派发维修工单</p>
          </el-button>
          <el-button :disabled='dioDetailsItem.sosStatus!=1' size="mini" type="warning" @click="sosClick(3)">
            <span>困人</span>
            <p style="margin: 10px 0 0 0;">立即派发维修工单</p>
          </el-button>
        </div>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination'
  import Process from '../../../bjgl/bjjl/components/process'
  import Operational from '../../../bjgl/bjjl/components/operational'
  import AlarmMessage from '../../../bjgl/bjjl/components/alarmMessage'
  import AlarmNotice from '../../../bjgl/bjjl/components/alarmNotice.vue'
  import {
    formatDate
  } from '@/util';
  let current_time = formatDate(new Date(), 'yyyy-MM-dd') + ' 00:00:00';
  let current_time1 = new Date(current_time).getTime();
  let starTime = new Date(current_time1 - 6 * 24 * 60 * 60 * 1000);
  let endTime = new Date(current_time1);

  export default {
    components: {
      Pagination,
      Process,
      Operational,
      AlarmMessage,
      AlarmNotice
    },

    props: {
      elevatorId: {
        type: String,
        default: '',
      },
      deviceCode: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        maxRange: 62,
        stateType: [],
        // 报警详情
        disabled: false,
        detailsItem: {
          seleId: 1,
          seleArr: [{
              value: 1,
              label: '报警消息',
            },
            {
              value: 2,
              label: '运行参数',
            },
            {
              value: 3,
              label: '处置流程',
            },
            {
              value: 4,
              label: '报警通知'
            }
          ],
        },
        dioDetails: false,
        dioDetailsItem: null,
        // 表格参数
        formInline: {
          current: 1,
          size: 10,
          sosStatus: 4,
          allTime: [starTime, endTime],
        },
        tableData: [],
        // 分页参数
        dictionarTotal: 0,
        // 状态
        typeDate: [], //字典表
        stateList: [{
          value: 1,
          label: '待处理'
        }, {
          value: 2,
          label: '已处理'
        }, {
          value: 3,
          label: '进行中'
        }, {
          value: 4,
          label: '已完成'
        }],
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        isShow: false,
        tableLoading: true
      }
    },
    methods: {
      disableddate(val) {
        const [start, end] = val;
        console.log(starTime, endTime, '时间')
        const days = this.getDays(start, end);
        console.log(days, '天数')
        if (days > this.maxRange) {
          this.$message.error('选择的日期范围不能超过62天！');
          // 自动调整为最大范围
          // const newEnd = this.addDays(start, this.maxRange - 1);
          this.formInline.allTime = [starTime, endTime]
        }
      },
      getDays(start, end) {
        console.log(start, end, '时间')
        const oneDay = 24 * 60 * 60 * 1000; // 每天毫秒数
        const diff = Math.abs(start - end);
        return (diff / oneDay) + 1;
      },
      // 详情操作按钮
      sosClick(type) {
        this.disabled = true
        this.dioDetailsItem.sosStatus = 2
        this.$http
          .post('/api/ele/app/sos/submit', {
            id: this.dioDetailsItem.id,
            submitType: type,
            sosTime: this.dioDetailsItem.sosTime
          })
          .then((res) => {
            if (res.data.success) {
              if (type == 5) {
                this.$confirm('确认误报成功，是否删除该报警误报记录？', '提示', {
                  cancelButtonText: '取消',
                  confirmButtonText: '确认',
                  type: 'warning'
                }).then(() => {
                  console.log(333)
                  this.$http
                    .post("/api/ele/web/sos/removeSosInfo", {
                      id: this.dioDetailsItem.id,
                      sosTime: this.dioDetailsItem.sosTime,
                    }).then((res) => {
                      console.log(666)
                      if (res.data.code == 200) {
                        this.dioDetails = false
                        this.onRefer()
                        this.$message.success(res.data.msg)
                      } else {
                        this.$message.error(res.data.msg)
                      }
                    })
                }).catch(() => {
                  this.dioDetails = false
                })
              } else {

                this.$message.success(res.data.msg)
              }
              // this.$message.success(res.data.msg)
            } else {
              this.$message.error(res.data.msg)
            }
            this.onRefer()
          })
      },
      //应急类型
      checkSosType(data) {
        for (var i = 0; i < data.length; i++) {
          if (data[i] == '13') {
            this.formInline.sosStatus = ''
          }
        }
      },
      // 详情
      showDetails(item) {
        this.$http
          .post('/api/ele/web/sos/getInfo', {
            id: item.id,
            sosTime: item.sosTime ? item.sosTime : ''
          })
          .then((res) => {
            if (res.data.success) {
              res.data.data.sosVideo.forEach((qs) => {
                let url = qs.videoUrl.split('?')
                qs.videoUrl = url[0]
                let urlname = url[0].split('/').pop().split('_')
                qs.videoName = url ? urlname.length > 1 ? `${urlname[1]}_${urlname[2]}` : url[0].split('/')
                  .pop() : ''
              })

              res.data.data.sosTypeName = item.sosTypeName
              res.data.data.sosStatusName = item.sosStatusName
              res.data.data.sosTypeName = item.sosType == -1 ? item.sosText : item.sosTypeName
              res.data.data.speed = res.data.data.speed !== '' ? parseFloat(res.data.data.speed) : 0
              res.data.data.speedarr = res.data.data.speedRecord.split(',')


              this.detailsItem.seleId = 1
              this.dioDetailsItem = res.data.data
              this.dioDetailsItem.emergencyNames = res.data.data.emergencyRescueUserNames.split(';')
              this.dioDetailsItem.maintainNames = res.data.data.maintainUsers.split(';')
              this.dioDetailsItem.propertyNames = res.data.data.propertyUsers.split(';')
              this.dioDetails = true
              console.log(this.dioDetailsItem, 'this.dioDetailsItem')
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 分页
      dictionarPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      // 获取类型
      getType(type, sta) {
        let obj
        let arr = []
        if (sta == 1) {
          arr = this.typeDate
        } else {
          arr = this.stateList
        }
        if (arr.length > 0) {
          arr.forEach((qs) => {
            if (qs.value == type) {
              obj = qs.label
            }
          })
        }
        return obj
      },
      //排序
      handleSort(column, prop, order) {
        if (column.prop == 'sosTime') {
          this.formInline.sosTimeSortSign = column.order === 'descending' ? 'DESC' : 'ASC'
        } else if (column.prop == 'callTime') {
          this.formInline.callTimeSortSign = column.order === 'descending' ? 'DESC' : 'ASC'
        }
        this.onRefer()
      },
      // 表格方法
      onRefer(ishm) {
        this.formInline.eleId = this.elevatorId
        this.disabled = false
        this.isShow = !(this.adminInfo.roleId == '99')
        if (this.formInline.allTime) {
          let atime = JSON.parse(JSON.stringify(this.formInline.allTime))

          if (new Date(atime[0]).getTime() < new Date('2022-12-01').getTime()) {
            this.$message.error('查询时间不得早于2022年12月')
            return
          }
          // if (new Date(atime[1]).getTime() > new Date(new Date().toLocaleDateString()).getTime()) {
          //   this.$message.error('查询时间不得晚于当天')
          //   return
          // }
          this.formInline.start = new Date(atime[0]).getTime()
          this.formInline.end = new Date(atime[1]).getTime() + 24 * 60 * 60 * 1000 - 1000
        } else {
          this.$message.error('请选择查询时间')
          return
        }
        this.formInline.sosTypeStr = this.formInline.sosTypeOption && this.formInline.sosTypeOption.length > 0 ? this
          .formInline.sosTypeOption.join(',') : ''
        this.tableLoading = true

        this.$http
          .post('/api/ele/web/sos/getList', this.formInline)
          .then((res) => {
            if (res.data.success) {
              res.data.data.records.forEach((qs) => {
                qs.sosTypeName = this.getType(qs.sosType, 1)
                qs.sosStatusName = this.getType(qs.sosStatus, 2)
              })
              this.tableData = res.data.data.records
              this.dictionarTotal = res.data.data.total
              this.$nextTick(() => {
                if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
            this.tableLoading = false
          })
      },
    },
    created() {
      this.$http
        .post('/api/system/web/dict/dictionary?codes=SOS_TYPE,ALARM_STATE')
        .then((res) => {
          if (res.data.success) {
            const Arr = res.data.data.SOS_TYPE.filter((item) => {
              if (this.adminInfo.userName != 'admin') {
                return item.value != '21' && item.value != '35'
              } else {
                return item
              }
            })
            this.typeDate = Arr
            this.stateType = res.data.data.ALARM_STATE
            // this.onRefer()
          }
        })
    },
  }
</script>
<style lang="scss" scoped>
  .errorSos {
    background: #00dcff;
    border-color: #00dcff;
    color: #fff
  }

  .yeScroll {
    overflow-y: scroll;
  }

  .nullDate {
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .yeScroll {
    overflow-y: scroll;
  }
</style>
